<template>
  <v-row class="d-flex">
    <v-col
      class="pl-0 ml-0"
      :class="$route.name === 'map' ? 'pt-0' : ''"
    >
      <div class="subtitle-2 lh-normal ma-0 text--darken-2 grey--text d-flex  mb-1">
        <b>{{ $t('passport.cadastr_number') + ' – ' }}</b>
        <p class="ml-2 second-text mb-0">{{ value.properties.cadastr_number }} </p>
      </div>
      <div class="subtitle-2 lh-normal ma-0 text--darken-2 grey--text d-flex">
        <b>{{ $t('passport.length') + ' – ' }}</b>
        <p class="ml-2 second-text mb-0">{{ value.properties.length }} </p>
      </div>
    </v-col>
    <v-col :class="$route.name === 'map' ? 'pt-0' : ''">
      <div class="subtitle-2 lh-normal ma-0 text--darken-2 grey--text d-flex mb-1">
        <b>{{ $t('passport.width') + ' – ' }}</b>
        <p class="ml-2 second-text mb-0">{{ value.properties.width }} </p>
      </div>
      <div class="subtitle-2 lh-normal ma-0 text--darken-2 grey--text d-flex">
        <b>{{ $t('passport.area') + ' – ' }}</b>
        <p class="ml-2 second-text mb-0">{{ value.properties.area }} </p>
      </div>
    </v-col>
    <v-col :class="$route.name === 'map' ? 'pt-0' : ''">
      <div class="subtitle-2 lh-normal ma-0 text--darken-2 grey--text d-flex  mb-1">
        <b>{{ $t('passport.origin_passportization_date') + ' – ' }}</b>
        <p class="ml-2 second-text mb-0">{{ timestampToDate(value.properties.origin_passportization_date) }} </p>
      </div>
      <div class="subtitle-2 lh-normal ma-0 text--darken-2 grey--text d-flex">
        <b>{{ $t('base.updated_at') + ' – ' }}</b>
        <p class="ml-2 second-text mb-0">{{ timestampToDate(value.properties.updated_at) }} </p>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import formatters from '@/components/mixins/formatters'

export default {
  name: 'InfoArea',
  mixins: [formatters],
  props: {
    value: Object
  }
}
</script>

<style scoped>
  .second-text {
    font-weight: 400 !important;
  }
</style>
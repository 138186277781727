<template>
  <v-card
    v-if="Object.keys(passports).length !== 0"
    flat
    class="mt-1 mb-3"
  >
    <div
      v-for="(value, key, index) in passports"
      :key="key"
    >
      <PassportInfo
        :statistics="value"
        :type="key"
        class="ml-0 mt-3"
        :objects-schema="objectsSchema"
      />
      <v-divider
        v-if="Object.keys(passports).length - 1 !== index"
        class="mt-6"
      />
    </div>
  </v-card>
  <v-progress-linear
    rounded
    height="10"
    indeterminate
    v-else-if="isLoading.data"
  ></v-progress-linear>
</template>

<script>
import dashboard from '@/components/mixins/dashboard'
import PassportInfo from '@/components/views/account/dashboard/passports/PassportInfo'
import loading from '@/components/mixins/loading'
import BasePreloader from '@/components/base/UI/BasePreloader'

export default {
  name: 'MapDashboard',
  mixins: [dashboard, loading],
  props: {
    value: Object
  },
  components: { BasePreloader, PassportInfo },
  methods: {
    async getStatistics () {
      this.isLoading.data = true
      const response = await this.$store.dispatch('server/get', { url: '/statistics?filter[passport_id][]=' + this.value.properties.id }, { root: true })
      this.$set(this, 'passports', { ...response })
      this.formatStatisticObjects(response)
      this.isLoading.data = false
      return true
    }
  }
}
</script>

<style scoped>

</style>
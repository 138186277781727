<template>
  <BaseCard
    is-small-height
    class="ml-0 pl-3 mt-0"
  >
    <template #content>
      <InfoArea v-model="value"/>
      <v-row class="pl-1">
        <MapDashboard :value="value"/>
      </v-row>
      <v-row>
        <v-col class="pl-0">
          <div
            v-for="(item, key) in infoProperties"
            :key="key"
            class="subtitle-2 ma-0 text--darken-2 grey--text"
            v-if="item"
          >
            <b class="ma-0">{{ $t('passport.' + key) + ': ' }}</b>
            <p class="ma-0 mb-2">{{ item || '–' }} </p>
          </div>
        </v-col>
      </v-row>
    </template>
  </BaseCard>
</template>

<script>
import BaseCard from '@/components/base/BaseCard'
import formatters from '@/components/mixins/formatters'
import MapDashboard from '@/components/views/account/map/detail/mapPassportDetail/passportInfo/MapDashboard'
import _ from 'lodash'
import InfoArea from '@/components/views/account/map/detail/mapPassportDetail/passportInfo/InfoArea'

export default {
  name: 'PassportInfo',
  mixins: [formatters],
  components: { InfoArea, MapDashboard, BaseCard },
  props: {
    value: Object
  },
  computed: {
    infoProperties () { //создание объекта по указанным ключам
      const keys = ['land_function', 'executing_organization', 'owner', 'classification_code', 'classification_code_detail', 'using_mode', 'address', 'origin_executor']
      return _.pick(this.value.properties, keys)
    }
  }
}
</script>

<style scoped>
  * {
    line-height: normal !important;
  }
</style>